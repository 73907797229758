import {
         SET_SWIPEABLE_INDEX,
         SET_LOADING_AUTH,
         RESET_SWIPEABLE_INDEX,
         STORE_RECIPIENT_CRITERIA
       } from './actions';

const initialState = {
  swipeableIndex: 0,
  loadingAuth: false,
  recipientCriteria: ''
}

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_RECIPIENT_CRITERIA:
      return {
        ...state,
        recipientCriteria: action.recipientCriteria
      }
    case SET_LOADING_AUTH:
      return {
        ...state,
        loadingAuth: action.status
      }
    case SET_SWIPEABLE_INDEX:
      return {
        ...state,
        swipeableIndex: action.index
      }
    case RESET_SWIPEABLE_INDEX:
      return {
        ...state,
        swipeableIndex: 0,
        loadingAuth: false
      }
    default:
      return state;
  }
}

export default loginReducer
