const errorCatcher = (status, history) => {
  switch (status) {
    case 403:
      history.push('/')
      break;
    case 500:
      history.push('/internal-error')
      break;
    default:
      history.push('/')
  }
}

export default errorCatcher
