import fr from './fr-FR'
import en from './en-US'
import de from './de-DE'

const allTraductions = {
  ...fr,
  ...en,
  ...de
}

export default allTraductions