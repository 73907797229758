import axios from "axios";
import config from "../config";

export const axiosInstanceSympl = axios.create({
  baseURL: config.url.URL_SYMPL,
  headers: {
    "X-Device-Name": "webapp-retour",
    "X-App-Version": 24,
    "X-Token": config.token
  }
});

export const axiosInstanceStock = axios.create({
  baseURL: config.url.URL_STOCK
});
