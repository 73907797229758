/**
*
* Fichier de configuration des step afficher à l'utilisateur
*/

export const stepsExchange  = [
  {
    name: 'mainStep',
    index: 0
  },
  {
    name: 'causeStep',
    index: 1
  },
  {
    name: 'exchangeStep',
    index: 2
  },
  {
    name: 'commentStep',
    index: 3
  }
]

export const stepsReturn = [
  {
    name: 'mainStep',
    index: 0
  },
  {
    name: 'causeStep',
    index: 1
  },
  {
    name: 'commentStep',
    index: 3
  }
]

export const stepsIncorectItem = [
  {
    name: 'mainStep',
    index: 0
  },
  {
    name: 'exchangeStep',
    index: 2
  },
  {
    name: 'commentStep',
    index: 3
  }
]
