import { SubmissionError } from 'redux-form'
import { addCurrentItems, setExternalCompanyId, addReturnSlipUrl, addCurrentUserInformation, addExchangeInformations } from '../../returnItems/actions'

import translate from '../../../i18n/translate';

import ShopifyClient from '../../../api/shopify'

/**
* @description
* Function permettant la validation asynchrone du formulaire
* de validation, ajoute les données d'authentification aux requêtes
* @param { object } values - Valeur des champs du formulaire
* @param { function } dispatch - fonction dispatch de redux
* @param { object } props - props
*/

const asyncValidate = async (values, dispatch, props) => {
  let newArrayItems = []
  if (values.selectedCommand !== undefined) {
    let commandIndex = values.selectedCommand;
    const { recipientEmail, recipientFirstname, recipientLastname, recipientPhoneNumber, randomId, recipientAddress, returnVoucher } = props.userCommands[commandIndex];
    props.handleAuthentificated()
    dispatch(addCurrentUserInformation({
      recipientEmail,
      recipientFirstname,
      recipientLastname,
      recipientPhoneNumber,
      recipientAddress,
      randomId,
      token: returnVoucher ? returnVoucher.token : returnVoucher
    }))
    if (props.userCommands[commandIndex].returnVoucher !== null && props.userCommands[commandIndex].returnVoucher.shipmentType === 'carrier') {
      dispatch(addReturnSlipUrl(props.userCommands[commandIndex].returnVoucher.returnSlipUrl))
      props.history.push('/already-generate-return-label')
    } else if (props.userCommands[commandIndex].returnVoucher !== null && props.userCommands[commandIndex].returnVoucher.shipmentType === 'courier') {
      dispatch(addExchangeInformations(props.userCommands[commandIndex].exchange));
      props.history.push('/already-request-courier')
    } else {


      var items = []
      // fetch shopify data for each items
      for(const item of props.userCommands[commandIndex].items) {
        let newItem = item
        try {
          const shopifyProduct = await ShopifyClient.getProductDataFromSku(item.externalReference)
            
          if (shopifyProduct) {
            newItem.shopify = shopifyProduct
          } else {
            newItem.shopify = undefined
          }
    
        } catch(error) {
          newItem.shopify = undefined
          console.log(error)
        }

        items.push(newItem)
      }

      // Déduplication des items avec une quantité supérieur à 1
      let pushInArray = (item) => {;
        if (item.quantity > 0) {
      		newArrayItems = [...newArrayItems, item]
          item.quantity = item.quantity - 1
          pushInArray(item);
        }
      }
      props.userCommands[commandIndex].items.forEach((item) => {
        pushInArray(item)
      })
      newArrayItems = newArrayItems.map((item, index) => {
        return {...item, index: index}
      })
      dispatch(addCurrentItems(props.userCommands[commandIndex].randomId, newArrayItems))
      dispatch(setExternalCompanyId(props.userCommands[commandIndex].senderCompany.id))
      props.history.push('/return-adress')
    }
  } else {
    throw new SubmissionError({
      selectedCommand: 'Champs requis'
    })
  }
}

export default asyncValidate
