import {
  SET_SHIPMENT_TYPE,
  SET_AVAILABLE_BOOKING_DATES
} from './actions';

const initialState = {
  shipmentType: 'carrier',
  availableBookingDates: []
}

const returnAdressReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHIPMENT_TYPE:
      return {
        ...state,
        shipmentType: action.shipmentType
      }
    case SET_AVAILABLE_BOOKING_DATES:
      return {
        ...state,
        availableBookingDates: action.availableBookingDates
      }
    default:
      return state
  }
}

export default returnAdressReducer
