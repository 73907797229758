import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import { formValueSelector } from 'redux-form'

import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/fr'

import { setbookingTimeToken } from './actions'
import translate from '../../../i18n/translate';
moment.locale('fr');

const DayNumber = styled.span`
  margin: 15px;
  font-size: 1.2em;
  font-weight: 200;
  cursor: pointer;
  padding-bottom: 3px;
  ${({ active }) => active && `
    border-bottom: 2px solid #3498db;
  `}
`

const SelectedDate = styled.span`
  font-size: 1.3em;
  font-weight: 200;
  letter-spacing: 0.2em;
`

const Container = styled.div`
  margin-top: 20px;
`

const SelectTimeSlot = styled.div`
  margin: 15px;
  display: inline-block;
  cursor: pointer;
`

const DialogTitleCustom = styled(DialogTitle)`
  text-align: center;
  font-weight: 300;
`

class SelectBookingDate extends Component {
  state = {
    index: 0,
  }
  render() {
    const { setbookingTimeToken, handleSwipeableIndex, availableBookingDates } = this.props;
    return (
      <div>
        <DialogTitleCustom id="alert-dialog-slide-title">
        {translate('choose_time_delivery')}
        </DialogTitleCustom>
        <DialogContent style={{textAlign: 'center'}}>
            {
              availableBookingDates.map((availableBookingDate, index) => {
                return (
                  <div key={index} style={{display: 'inline-block'}}>
                    <DayNumber onClick={() => this.setState({index : index})} active={this.state.index === index} id={`day-number-${index}`}>
                      {
                        moment(availableBookingDate.date, 'YYYY-MM-DD').format('D')
                      }
                    </DayNumber>
                  </div>
                )
              })
            }
          <Container>
            <SelectedDate id="selected-date">
              {
                moment(availableBookingDates[this.state.index].date, 'YYYY-MM-DD').format('dddd D MMMM YYYY')
              }
            </SelectedDate>
          </Container>
          <Container>
            {
              availableBookingDates[this.state.index].timeSlots.map((timeSlot, index) => {
                return (
                  <SelectTimeSlot key={index} onClick={() => {
                      setbookingTimeToken(timeSlot.token)
                      handleSwipeableIndex(1)
                    }}>
                    <Button variant="contained"
                            color="primary"
                            id={`time-slot-${index}`}>
                      {
                        `${moment(timeSlot.start).format('H:mm')} - ${moment(timeSlot.end).format('H:mm')}`
                      }
                    </Button>
                  </SelectTimeSlot>
                )
              })
            }
          </Container>
        </DialogContent>
      </div>
    )
  }
}

const returnAdressSelector = formValueSelector('returnAdress')
const mapStateToProps = state => {
  return {
    postalCode: returnAdressSelector(state, 'postalCode'),
    countryCode: returnAdressSelector(state, 'countryCode'),
    availableBookingDates: state.returnAdress.availableBookingDates
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setbookingTimeToken: (token) =>
      dispatch(setbookingTimeToken(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectBookingDate))
