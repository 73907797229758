import { OPEN_SNACKBAR_ERROR, CLOSE_SNACKBAR_ERROR } from './actions';

const initialState = {
  openSnackbar: false,
  errorCode: undefined
}

const snackbarErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SNACKBAR_ERROR:
      return {
        ...state,
        openSnackbar: true,
        errorCode: action.errorCode
      }
    case CLOSE_SNACKBAR_ERROR:
      return initialState
    default:
      return state;
  }
}

export default snackbarErrorReducer
