import { LOCALES } from '../locales'

const traductions = {
 [LOCALES.FRENCH]: {
   "home_title": "RETOURNER OU ÉCHANGER VOTRE COMMANDE",
   "home_1": "Les retours et échanges sont possibles dans les 14 jours suivant la réception du colis et sont gratuits en France.",
   "home_2": "Il vous suffit d’entrer ci-dessous votre email ou numéro de téléphone ainsi que votre numéro de commande.",
   "home_3": "Bon ça parait évident mais c’est mieux si on le dit. Les pièces ne doivent pas être usagées, abîmées, retouchées, ou lavées. Elles doivent être renvoyées dans leur emballage d’origine : code barre, étiquette, kraft, papier, pochette...",
   "home_4": "Et pour ",
   "home_4_1": "les Parisiens & Petite couronne",
   "home_4_2": ", notre service de coursier est à votre disposition pour faire vos retours sur rendez-vous au bureau ou à la maison.",
   "login": "Connexion",
   "phone": "Numéro de téléphone",
   "email_phone": "Email / Téléphone",
   "order_number": "Numéro de commande",
   "order": "Commande",
   "login_comment": "* Si vous faites un second échange pour une même commande, ajoutez RR devant votre numéro de commande.",
   "submit": "Valider",
   "logout": "Déconnexion",
   "return": "Retour",
   "cancel": "Annuler",
   "next": "Suivant",
   "return_login": "Retour à la page de connexion",
   "select_order": "Sélectionnez une commande",
   "required": "Champs requis",
   "loading": "Chargement",
   "invalid_email": "Adresse email invalide",
   "email_input": "Veuillez renseigner votre adresse email pour recevoir votre message de confirmation",
   "already_generated_returns": "Vous avez déjà généré vos documents de retour",
   "download_returns": "Télécharger mes documents de retour",
   "request_received": "Votre demande a bien été prise en compte",
   "booking_courier_error": "Erreur lors de votre prise de rendez-vous coursier",
   "booking_courier_confirm": "Votre confirmation de rendez-vous avec le coursier vient de vous être envoyée par e-mail",
   "booking_courier_issues": "Nous rencontrons quelques difficultés pour prendre rendez-vous avec votre coursier, nous vous invitons à réessayer dans quelques instants. Si le problème persiste, n'hésitez pas à nous envoyer un email à sav@sympl.fr",
   "try_again": "Réessayer",
   "return_place": "À quelle adresse souhaitez-vous recevoir votre échange",
   "address": "Adresse",
   "city": "Ville",
   "postal_code": "Code postal",
   "country": "Pays",
   "item_exchange_by": "Votre produit va être échangé par",
   "item_returned": "Votre produit va être retourné",
   "item_not_received": "Vous n'avez pas reçu le produit",
   "select_items": "Sélectionnez un par un, le ou les articles que vous souhaitez échanger ou renvoyer.",
   "confirm_return": "Valider ma demande de retour",
   "book_courier": "Réserver un coursier",
   "exchange_reason": "Quelle est la raison de votre demande d'échange ?",
   "return_reason": "Quelle est la raison de votre demande de retour ?",
   "selected_reason": "Raison sélectionnée :",
   "too_big": "Trop grand",
   "too_small": "Trop petit",
   "color_is_not_suitable": "La couleur ne convient pas",
   "cut_is_not_suitable": "La coupe ne convient pas",
   "material_is_not_suitable": "La matière ne convient pas",
   "ordered_two_different_sizes": "J’ai commandé 2 tailles différentes",
   "ordered_two_different_colors": "J'ai commandé 2 couleurs différentes",
   "damaged_item": "Article endommagé",
   "changed_mind": "J’ai trouvé un produit similaire entre temps",
   "too_late": "Le produit est arrivé trop tard dans la saison",
   "add_comment": "Souhaitez-vous ajouter un commentaire ?",
   "exchange_item": "Quel article souhaitez-vous en échange ?",
   "available_15days": "Peut-être disponible sous 15 jours",
   "exchange": "Échange",
   "exchange_not_possible": "Il est impossible d'échanger ce produit",
   "refund": "Remboursement",
   "wrong_item": "Mauvais article livré",
   "no_order_to_return": "Vous ne possédez aucune commande retournable",
   "order_not_found": "Commande introuvable",
   "order_cannot_be_returned": "Il semble que votre commande ne puisse pas être retournée",
   "country_not_available": "Impossible de générer une étiquette retour de pour une commande expédié dans ce pays",
   "problem_creating_return": "Il semblerait qu'il y ait eu un souci lors de la génération de votre étiquette retour. Veuillez réessayer dans quelques secondes",
   "from_which_address": "Depuis quelle adresse souhaitez-vous retourner vos articles ?",
   "confirm_cancel_courier": "Êtes-vous sûr de vouloir annuler votre réservation de coursier ?",
   "courier_cancelled": "Votre demande de coursier a bien été annulée",
   "courier_cancelled_error": "Erreur lors de l'annulation de votre bon de retour",
   "send_error_1": "Envoyez-nous un email à",
   "send_error_2": ", en précisant le code d'erreur",
   "send_error_3": "et l'id",
   "carrier_choice": "Choisissez votre transporteur",
   "pickup_on_map": "Afficher sur une carte",
   // Haut 
   "col": "Col",
    "epaules": "Epaules",
    "poitrine": "Poitrine",
    "aisselles": "Aisselles",
    "ventre": "Ventre",
    "longueur_manches": "Longueur des manches",
    "largueur_manches": "Largueur des manches",
    // Bas
    "taille" : "Taille",
    "fourche" : "Fourche",
    "hanches" : "Hanches",
    "cuisses" : "Cuisses",
    "mollets" : "Mollets",
    "chevilles" : "Chevilles",
    // Chaussures
    "talon" :"Talon",
    "orteils" :"Orteils",
    "longueur_totale" : "Longueur Totale",
    "globalement_trop_large" : "Globalement trop large",
    "globalement_trop_serre" : "Globalement trop serré",
    "footer_contact":"N'hésitez pas à nous contacter si vous avez des questions, à l’adresse",
    "already_courier": "Votre demande de coursier a bien été annulée",
    "info_courier_1": "Il va se présenter le ",
    "and": " et ",
    "between": " entre ",
    "at_this_address": " à l'adresse suivante :",
    "error": "Erreur",
    "error_page_1":"Cette page est temporairement indisponible",
    "error_page_2":"Pas de panique nos agents sont sur le coup",
    "not_found":"PAGE NON TROUVÉE",
    "not_found_1":"Cette page n'est pas disponible",
    "no_courier_available": "Plus de coursier disponible",
    "eligible_for_courier": "Bonne nouvelle votre adresse est éligible à un échange ou un retour par coursier",
    "not_eligible_for_courier": "Il n'y a malheureusement plus de créneaux disponibles pour un échange ou un retour par coursier, vous pouvez tout de même faire votre retour par voie postale.",
    "poste_office":"Voie Postale",
    "courier":"Coursier",
    "confirm_completed":"Êtes-vous sûr d'avoir terminé ?",
    "info_before_complete":"Une fois validé il vous sera impossible de faire une nouvelle modificationsur votre commande",
    "generation_error": "Erreur lors de la génération de votre bon de retour",
    "return_label_sent_email":"Votre étiquette de retour vient de vous être envoyée par e-mail",
    "difficult_return_label_generation":"Notre transporteur rencontre quelques difficultés pour la génération de votre étiquette retour, nous vous invitons à réessayer dans quelques instants. Si le problème persiste, n'hésitez pas à nous envoyer un email à sav@sympl.fr",
    "email":"Email",
    "lastname":"Nom",
    "firstname":"Prénom",
    "choose_time_delivery":"Choisissez l'heure et le jour du rendez-vous",
    "request_return_label":"Demander mon étiquette de retour",
    "book_courier":"Réserver un coursier sympl",
    "comment":"Commentaire",
    "return_item":"Retourner",
    "unknow_error":"Une erreur inconnue s'est produite",
    "out_of_stock":"Rupture de stock",
    "footer_faq":"Besoin d’aide ? Plus de détails",
    "here":"ici",
    "home_3":"Pour les commandes à l’étranger ou d’outre-mer, la procédure est la même mais le retour sera à vos frais.",
 }
}

export default traductions