import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faUndo, faTimes } from '@fortawesome/free-solid-svg-icons'

import surpriseLogo from '../../../img/surprise-logo.png'
import symplLogo from '../../../logo.png'

import styled from 'styled-components';
import translate from '../../../i18n/translate';

const IconContainer = styled.div`
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  overflow: hidden;
`

const FontAwesomeIconCustom = styled(FontAwesomeIcon)`
  font-size: 2em;
  display: inline-block;
  padding: 0 15px;
  position: relative;
  &:after {
    background: #ddd;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
   top: 50%;
   width: 400%;
  }
`
/**
* Composant qui affiche les article possible de retourné ou échanger
* sous forme de carte
*/

const CardItem = ({classes, item, id, companyExternalId}) => {
  /**
  * @function getDesiredItemName
  * @description map l'id du produit avec son nom pour l'afficher sur la
  * carte
  */
  const getDesiredItemName = () => {
      if (item.desiredItem) {
        return (
          <Typography align="center" style={{fontSize:'1.2em', fontWeight: 100}}>
            {item.desiredItem.description} / {item.desiredItem.color} / {item.desiredItem.size}
          </Typography>
        )
      }
      return '';
    }
  /**
  * @function getReturnNatureCode
  * @description retourne un composant différent selon
  * si l'utilisateur souhaite faire un échange ou un retour
  */
  const getReturnNatureCode = () => {
    switch (item.returnNatureCode) {
      case 'exchange':
        return (
          <div>
            <IconContainer>
              <FontAwesomeIconCustom icon={faExchangeAlt}/>
            </IconContainer>
            <Typography align="center" style={{fontSize:'1em', fontWeight: 'bold', marginBottom: 20}}>
              {translate('item_exchange_by')}
            </Typography>
          </div>
        )
      case 'return':
        return (
          <div>
            <IconContainer>
              <FontAwesomeIconCustom icon={faUndo}/>
            </IconContainer>
            <Typography align="center" style={{fontSize:'1em', fontWeight: 'bold'}}>
            {translate('item_returned')}
            </Typography>
          </div>
        )
      case 'incorectItem':
        return (
          <div>
            <IconContainer>
              <FontAwesomeIconCustom icon={faTimes}/>
            </IconContainer>
            <Typography align="center" style={{fontSize:'1em', fontWeight: 'bold'}}>
            {translate('item_not_received')}
            </Typography>
          </div>
        )
      default:
        return <div></div>
    }
  }

  return (
    <Card id={id}>
      <CardActionArea>
        <CardMedia component="img"
                   alt="Product"
                   width="130"
                   image={item.pictures.length > 0 ? item.pictures[0] : surpriseLogo}
                   title="Product"/>
        <CardContent>
          <Typography align="center" style={{fontSize:'1.5em', fontWeight: 100}}>
            {item.description}
          </Typography>
          <Typography align="center" style={{fontSize:'1.5em', fontWeight: 100}}>
            {item.color} / {item.size}
          </Typography>
          <Typography align="center" style={{fontSize:'1.2em', fontWeight: 'bold', color:'#9B9B9B'}}>
            Ref : {item.externalReference}
          </Typography>
          <Collapse in={item.expanded} timeout={{enter: 800}} unmountOnExit>
            {
              getReturnNatureCode()
            }
            {
              getDesiredItemName()
            }
          </Collapse>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const mapStateToProps = state => ({
  companyExternalId: state.returnItem.companyExternalId
})

export default connect(mapStateToProps)((withStyles()(CardItem)));
