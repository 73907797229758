const stage = {
  url: {
    URL_SYMPL: 'https://stage.sympl.fr',
    URL_STOCK: 'https://stock-stage.sympl.fr'
  },
  token: '',
};

const prod = {
  url: {
    URL_SYMPL: 'https://live.sympl.fr',
    URL_STOCK: 'https://stock.sympl.fr'
  },
  token: '63438565e82fe93cc3cbd7d1eed40e06eb0b1560fe4b7f0c2886d09df11d273f',
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : stage;

export default config;