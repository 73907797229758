export const SET_LOADING_AUTH = 'SET_LOADING_AUTH'
export const setLoadingAuth = (status) => ({type: SET_LOADING_AUTH, status})

export const RESET_SWIPEABLE_INDEX = 'RESET_SWIPEABLE_INDEX'
export const resetSwipeableIndex = () => ({type: RESET_SWIPEABLE_INDEX})

export const SET_SWIPEABLE_INDEX = 'SET_SWIPEABLE_INDEX'
export const setSwipeableIndex = (index) => ({type: SET_SWIPEABLE_INDEX, index})

export const STORE_RECIPIENT_CRITERIA = 'STORE_RECIPIENT_CRITERIA'
export const storeRecipeientCriteria = (recipientCriteria) => ({ type: STORE_RECIPIENT_CRITERIA, recipientCriteria})
