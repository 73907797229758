import React, { Component } from 'react'
import { Redirect, withRouter } from "react-router-dom"
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import SwipeableViews from 'react-swipeable-views'

import { resetSwipeableIndex } from './actions'

import AuthStep from './authStep/AuthStep'
import SelectCommandStep from './selectedCommandStep/SelectCommandStep'
import translate from '../../i18n/translate';

import iosDownload from '../../img/download-on-app-store.png'
import androidDownload from '../../img/download-on-google-play.png'

const ConnexionBackground = styled(Paper)`
  width: 20%;
  padding: 20px 40px 20px 40px;
  @media (max-width: 600px) {
    width: 70%;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  margin: 4%;
  font-size: 1.8em;
  text-align: center;
`

const Paragraph = styled.div`
  margin: 4%;
  font-size: 14px;
  text-align: center;
  max-width: 450px;
`

const DownloadAppLink = styled.a`
  margin: 60px 15px 0px 15px;
`

/**
 * Composant de la page de login
 */

class Login extends Component {
  state = {
    redirectToReferrer: false
  };

  componentDidMount() {
    this.props.resetSwipeableIndex();
  }
  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" }};
    const { redirectToReferrer } = this.state;
    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }
    return (
      <div>
        <Container>
        <Paragraph>
          <h1 style={{fontSize:"14px",textAlign:"center",marginBottom:"30px",fontFamily:"Arial Black"}}>{translate("home_title")}</h1>
          <p style={{color: '#000'}}>{translate("home_1")}</p>
          <p>{translate("home_3")}</p>
          <p>{translate("home_2")}</p>
        </Paragraph>
        </Container>
        <Container>
          <ConnexionBackground elevation={0}>
            <SwipeableViews index={this.props.swipeableIndex} disabled>
                <AuthStep handleAuthentificated={this.props.handleAuthentificated}/>
                <SelectCommandStep handleAuthentificated={this.props.handleAuthentificated}/>
            </SwipeableViews>
          </ConnexionBackground>
        </Container>
        <Container>
          <Paragraph>
            {translate("footer_faq")} <b><a style={{color: '#000',textDecoration:'none'}} href="https://surprise-paris.com/pages/faq#block-1585929648491">{translate("here")}</a></b>.
            <br></br><br></br>
            <span>{translate("footer_contact")} <b><a style={{color: '#000',textDecoration:'none'}} href="mailto:hello@surprise-paris.com">hello@surprise-paris.com</a></b></span>
          </Paragraph>
        </Container>
        {/* <Container>
          <DownloadAppLink href="https://itunes.apple.com/fr/app/sympl/id1224160817?mt=8." rel="noopener noreferrer" target="_blank">
            <img src={iosDownload} alt="Download app on ios" height="40"/>
          </DownloadAppLink>
          <DownloadAppLink href="https://play.google.com/store/apps/details?id=com.sympl.app" rel="noopener noreferrer" target="_blank">
            <img src={androidDownload} alt="Download google play" height="40"/>
          </DownloadAppLink>
        </Container> */}
     </div>
    );
  }
}

const mapStateToProps = (state) => ({
  swipeableIndex: state.login.swipeableIndex
})

const mapDispatchToProps = (dispatch) => ({
  resetSwipeableIndex: () => {
    dispatch(resetSwipeableIndex())
  }
})

Login = reduxForm({
  form: 'login',
})(Login)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
