import React from 'react';
import { Route, Redirect } from "react-router-dom";

/**
 * Composant permettant de créer des route privé
 * accessible uniquement si l'utilisateur est authhentifier
 * dans le cas contraire l'utilisateur est redirigé vers la page de login
 */

const PrivateRoute = ({ isAuthenticated ,component: Component, ...rest}) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default PrivateRoute;
