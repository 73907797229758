import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import returnItemReducer from './component/returnItems/reducer'
import courierReducer from './component/returnItems/confirmationCourierModal/reducer'
import snackbarErrorReducer from './component/snackbarError/reducer'
import loginReducer from './component/auth/reducer'

import returnAdressReducer from './component/returnAdress/reducer'

export default combineReducers({
  form: formReducer,
  returnItem : returnItemReducer,
  snackbarError: snackbarErrorReducer,
  login: loginReducer,
  returnAdress: returnAdressReducer,
  courier: courierReducer
})
