import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga';

import styled from 'styled-components'

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Login from './component/auth/Login'
import PrivateRoute from './component/auth/PrivateRoute'
import ReturnAdress from './component/returnAdress/ReturnAdress'
import ReturnItems from './component/returnItems/home/ReturnItems'
import AlreadyGenerateReturnLabel from './component/alreadyGenerateReturnLabel/AlreadyGenerateReturnLabel'
import AlreadyRequestCourier from './component/alreadyRequestCourier/AlreadyRequestCourier'
import notFound from './component/errorPage/notFound'
import internalError from './component/errorPage/internalError'
import logo from './logo.png'
// import backgroudLogin from './img/background-login.jpg'
import SnackbarError from './component/snackbarError/SnackbarError'
import ErrorBoundary from './ErrorBoundary'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import Provider from './i18n/Provider'
import { LOCALES } from './i18n/locales'
import translate from './i18n/translate';
import ReactFlagsSelect from 'react-flags-select';

if (process.env.REACT_APP_STAGE === 'prod') {
  /*ReactGA.initialize('UA-130132855-1');
  ReactGA.initialize('UA-130036507-1');
  ReactGA.pageview(window.location.pathname + window.location.search);*/
}

const Header = styled.header`
  background-color: #ffffff;
  box-shadow: 0px 1px 7px rgba(0,0,0,.5);
  height: 80px;
  display: flex;
  justify-content: center;
`

const LogoutButtonContainer = styled.div`
  position: absolute;
  right: 6%;
  top: 20px;
`
const styles = theme => ({
  logoutIcon: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    color: '#000',
    fontSize: 20,
    marginTop: 10,
    cursor: 'pointer'
  },
  logoutButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    borderRadius: 0
  },
});

/**
* Composant principale gestion des routes de l'application
* avec react-router-dom
*/

class App extends Component {
  state = {
    isAuthenticated: false, locale: LOCALES.FRENCH
  };

  handleAuthentificated() {
    this.setState({ isAuthenticated: true });
  }

  onSelectFlag(countryCode) {
    if (countryCode === "FR") {
      this.setState({locale: LOCALES.FRENCH})
    }
    if (countryCode === "GB") {
      this.setState({locale: LOCALES.ENGLISH})
    }
    if (countryCode === "DE") {
      this.setState({locale: LOCALES.DEUTCH})
    }
  }

  render() {
    const { isAuthenticated, locale } = this.state;
    const { classes } = this.props;
    return (
      <Provider locale={locale}>
      <ErrorBoundary>
          <SnackbarError />
          <Header>
            <img src={logo} alt="logo surprise" height="60" style={{marginTop: 8}}/>
            <LogoutButtonContainer>
              {
                isAuthenticated === true ?
                <div>
                  <Button className={classes.logoutButton} variant="outlined" color="primary" onClick={() => window.location.reload()}>
                  {translate("logout")}
                  </Button>
                  <FontAwesomeIcon className={classes.logoutIcon} icon={faSignOutAlt} onClick={() => window.location.reload()}/>
                </div>
                : ''
              }
            </LogoutButtonContainer>
          </Header>
          <Switch>
            <Route exact path="/" render={props => <Login handleAuthentificated={this.handleAuthentificated.bind(this)}/>}/>
            <PrivateRoute exact path="/internal-error" component={internalError}/>
            <PrivateRoute path="/already-generate-return-label" component={AlreadyGenerateReturnLabel} isAuthenticated={isAuthenticated}/>
            <PrivateRoute path="/already-request-courier" component={AlreadyRequestCourier} isAuthenticated={isAuthenticated}/>
            <PrivateRoute path="/return-adress" component={ReturnAdress} isAuthenticated={isAuthenticated}/>
            <PrivateRoute path="/return-items" component={ReturnItems} isAuthenticated={isAuthenticated}/>
            <Route component={notFound}/>
          </Switch>
          <ReactFlagsSelect
                  placeholder="Language"
                  className="menu-flags"
                  defaultCountry="FR"
                  countries={["GB", "FR", "DE"]}
                  customLabels={{"GB": "EN","FR": "FR", "DE" : "DE"}}
                  onSelect={this.onSelectFlag.bind(this)}
                  fullWidth={true}
                  selectButtonClassName="menu-flags-button"
                />
      </ErrorBoundary>
      </Provider>
    );
  }
}

export default withStyles(styles)(App);

