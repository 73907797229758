import store from '../../../store'
import { createReturn } from '../../../api/api'
import { formValueSelector } from 'redux-form'
import { successCreateReturn, loadingCreateReturn } from '../../returnItems/actions'

const returnAdressSelector = formValueSelector('returnAdress')
const emailFormSelector = formValueSelector('emailFormCarrier')
const exchangeAdressSelector = formValueSelector('exchangeAdress')

const createReturnByCarrier = () => {
  store.dispatch(loadingCreateReturn(true))
  let currentStore = store.getState();
  let command = {
    returnVoucher : {
      commandRandomId: currentStore.returnItem.commandRandomId,
      shipmentType: 'carrier',
      shipFromAddress: returnAdressSelector(currentStore, 'street', 'city', 'postalCode', 'countryCode'),
      items: [],
      shipToAddress: exchangeAdressSelector(currentStore, 'street', 'city', 'postalCode', 'countryCode')
    },
    emailSlipRecipient: emailFormSelector(currentStore, 'contactEmail')
  }
  for (let i = 0; i < currentStore.returnItem.commandItems.length; i++) {
    if (currentStore.returnItem.commandItems[i].returnNatureCode !== undefined) {
      let item = {
        desiredItem: currentStore.returnItem.commandItems[i].desiredItem,
        returnedItem: {
          description: currentStore.returnItem.commandItems[i].description,
          color: currentStore.returnItem.commandItems[i].color,
          size: currentStore.returnItem.commandItems[i].size,
          ean13: currentStore.returnItem.commandItems[i].ean13
        },
        returnNatureCode: currentStore.returnItem.commandItems[i].returnNatureCode,
        returnReasonCode: currentStore.returnItem.commandItems[i].returnReasonCode,
        customReturnReason: currentStore.returnItem.commandItems[i].customReturnReason
      }
      command.returnVoucher.items.push(item)
    }
  }

  createReturn(command).then(res => {
    if (res.data.success === true) {
      store.dispatch(successCreateReturn())
      store.dispatch(loadingCreateReturn(false))
    } else {
      store.dispatch(loadingCreateReturn(false))
    }
  }, err => {
    console.log(err);
    store.dispatch(loadingCreateReturn(false))
  })
}

export default createReturnByCarrier;
