import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import translate from '../../../i18n/translate';
const Alert = () => {
  return (
    <div>
      <DialogTitle id="alert-dialog-slide-title" style={{textAlign: 'center'}}>
        {
          translate('confirm_completed')
        }
      </DialogTitle>
      <DialogContent style={{textAlign: 'center'}}>
        <DialogContentText id="alert-dialog-slide-description">
          {translate('info_before_complete')}
        </DialogContentText>
      </DialogContent>
    </div>
  )
}

export default Alert
