import { SET_BOOKING_TIME_TOKEN } from './actions'

const initialState = {
  bookingTimeToken: ''
}

const courierReducer = (state = initialState, action) => {
  switch (action.type) {
      case SET_BOOKING_TIME_TOKEN:
        return {
          ...state,
          bookingTimeToken: action.token
        }
    default:
      return state
    }
  }
export default courierReducer;
