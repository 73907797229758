export const returnReasonCode = [
  {
    name: 'Trop Grand',
    code: 'too_big'
  },
  {
    name: 'Trop Petit',
    code: 'too_small'
  },
  {
    name: 'La couleur ne convient pas',
    code: 'color_is_not_suitable'
  },
  {
    name: 'La coupe ne convient pas',
    code: 'cut_is_not_suitable'
  },
  {
    name: 'La matière ne convient pas',
    code: 'material_is_not_suitable'
  },
  {
    name: 'J\'ai commandé 2 tailles différentes',
    code: 'ordered_two_different_sizes'
  },
  {
    name: 'J\'ai commandé 2 couleurs différentes',
    code: 'ordered_two_different_colors'
  },
  {
    name: 'Article endommagé',
    code: 'damaged_item'
  }
]
