import { axiosInstanceSympl, axiosInstanceStock } from './axiosConfig';

// Sympl request
export const login = (data) => axiosInstanceSympl.get('/api/commands/get-by-recipient', {params : data })

export const getCanRequestCourier = (data) => axiosInstanceSympl.get('/api/customer/can-request-courier', {params : data })

export const getBookingDates = (data) => axiosInstanceSympl.get('/api/collect-points/exchange-booking-dates', {params: data})
export const createReturn = (data) => axiosInstanceSympl.post('/api/return-vouchers/create', data)

export const getPickupPoints = (data) =>
  axiosInstanceSympl.get("/api/pickup-points/available", {
    params: {
      ...data,
      offerCode: "STD_PRO_RETURN",
      carrierOfferId: 265
    }
  });

export const cancelReturnVoucher = (token) => axiosInstanceSympl.post(`/api/return-vouchers/token=${token}/cancel`)

// Stock manager request
export const getSwitchableProducts = (data) => axiosInstanceStock.get('/api/product/get-switchable-products', {params : data})
