/**
* @function validate
* @param {object} - Object contenant les valeurs des champs du formulaire
* @description validation du formulaiure ReturnAdress
* @return {object} - contenant les champs et message d'erreur
*/
import translate from '../../../../../i18n/translate';
const validate = values => {
  const errors = {}
  if (!values.desiredItem) {
    errors.desiredItem = translate("required")
  }
  return errors;
}

export default validate;
