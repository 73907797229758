import { SubmissionError } from 'redux-form'
import validateEmail from '../../utilis/validateEmail'
import translate from '../../../i18n/translate';
const submitEmailFormCarrier = (values, dispatch, props) => {
  if (!values.contactEmail) {
    throw new SubmissionError({
      contactEmail: translate("required")
    })
  }
  if (!validateEmail(values.contactEmail)) {
    throw new SubmissionError({
      contactEmail: translate("required")
    })
  }
  props.handleSwipeableIndex(2)
}

export default submitEmailFormCarrier
