import React from 'react'
import Button from '@material-ui/core/Button'

import './buttonLoading.css'
import translate from '../../../i18n/translate';

const ButtonLoading = () => {
  return (
  <Button id="loading-button"
          variant="contained"
          color="primary">
      {translate('loading')}
      <div className="spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  </Button>
  )
}

export default ButtonLoading;
