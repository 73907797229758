import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'

import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Grid from '@material-ui/core/Grid'

import { setShipmentType } from './actions'
import translate from '../../i18n/translate';

/**
* @function Transition
* @param {object} - props
* @return { Component }
* @description Ajoute un effet d'entré et de sortie  pour la modal
*/
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

/**
* Composant permettant à l'utilisateur de choisir entre faire un
* retour / échange par coursier ou par voie postale.
* cette modal dialog s'ouvre uniquement dans le cas ou l'utilisateur souhaite
* faire une retour ou un échange depuis une zone déservie par les coursiers sympl
*/

class Modal extends React.Component {
  render() {
    const { open, history, setShipmentType, availableBookingDates } = this.props;
    return (
      <div>
        <Dialog open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">

            <DialogTitle style={{textAlign:'center'}}>
              {
                availableBookingDates.length > 1 ?
                translate("book_courier")
                :
                translate("no_courier_available")
              }
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description" style={{textAlign:'center'}}>
                {
                  availableBookingDates.length > 1 ?
                  translate("eligible_for_courier")
                  :
                  translate("not_eligible_for_courier")
                }
              </DialogContentText>
              <Grid container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={24}
                style={{marginTop: '6%'}}>
                <Grid item md={4} style={{textAlign: 'center'}}>
                  <Button id="send-by-carrier-button" onClick={() => {
                      setShipmentType('carrier')
                      history.push('/return-items')
                    }}
                    variant="outlined"
                    color="primary">
                      {translate("poste_office")}
                  </Button>
                </Grid>
                <Grid item md={4} style={{textAlign: 'center'}}>
                  <Button id="send-by-courier-button" onClick={() => {
                      setShipmentType('courier')
                      history.push('/return-items')
                    }}
                    disabled={availableBookingDates.length < 1}
                    variant="outlined"
                    color="primary">
                    {translate("courier")}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = state => {
  return {
    availableBookingDates: state.returnAdress.availableBookingDates
  }
}

const mapDispatchToProsps = dispatch => {
  return {
    setShipmentType: (shipmentType) =>
      dispatch(setShipmentType(shipmentType))
  }
}

export default connect(mapDispatchToProps, mapDispatchToProsps)(withRouter(Modal));
