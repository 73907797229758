import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'

import RenderTextField from '../../utilis/renderField/RenderTextField'
import submit from './submit'
import styled from 'styled-components'
import { withRouter } from "react-router-dom"
import queryString from 'query-string'
import ButtonLoading from './ButtonLoading'
import translate from '../../../i18n/translate';

const ValidateButtonContainer = styled.div`
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
`

const Title = styled.div`
  text-align: center;
  font-size: 1.4em;
  font-weight: 200;
  margin-bottom: 30px;
  font-family: "Arial Black";
`

class AuthStep extends Component {
  state = {
    displayLoader: false
  }

  componentDidMount() {
    this.props.initialize(queryString.parse(this.props.location.search))
  }

  render() {
    const { handleSubmit, loadingAuth } = this.props;
    return (
      <form onSubmit={handleSubmit(submit)}>
        <Title>{translate('login')}</Title>
        <Field id="recipient-criteria"
                name="recipientCriteria"
                label={translate('email_phone')}
                style={{width: '100%'}}
                component={RenderTextField}/>
          <Field id="external-reference"
                 name="externalReference"
                 label={translate('order_number')}
                 type="text"
                 style={{width: '100%', marginTop: 20}}
                 component={RenderTextField}/>
        <ValidateButtonContainer>
          {
            loadingAuth ?
            <ButtonLoading />
            :
            <Button id="login-button"
                    type="submit"
                    variant="contained"
                    color="primary">
              {translate("submit")}
            </Button>
          }
        </ValidateButtonContainer>
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  loadingAuth: state.login.loadingAuth
})

AuthStep = reduxForm({
  form: 'login',
  destroyOnUnmount: false
})(AuthStep)

export default connect(mapStateToProps)((withRouter(AuthStep)));
