import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import translate from '../../i18n/translate';
class notFound extends Component {
  render() {
    return(
      <div style={{backgroundColor: '#fff', paddingBottom: '40px'}}>
      <Grid container
            direction="column"
            justify="center"
            alignItems="center"
            style={{fontSize: '2.4em', marginTop: '5%'}}>
              <h1>404 - {translate("not_found")}</h1>
              <p>{translate("not_found_1")}</p>
              <div>
                <Button variant="contained"
                        color="primary"
                        onClick={() => {
                          this.props.history.push('/')
                        }}>{translate("return_login")}</Button>
              </div>
        </Grid>
      </div>
    )
  }
}

export default withRouter(notFound)
