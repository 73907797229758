import React, { Component } from 'react';
import RenderSelectField from '../../../../utilis/renderField/RenderSelectField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Field , reduxForm } from 'redux-form';
import { handleChangeSwipeableIndex, setDesiredItem } from '../../../actions';
import { connect } from 'react-redux';
import validate from  './validate';
import { formValueSelector } from 'redux-form'
import styled from 'styled-components'
import translate from '../../../../../i18n/translate';
// @TODO delete mock
// import data from './mockSwitchable.json';


const HelperTextLowQuantity = styled.p`
  color: #F31431;
  font-size: 0.8em;
  font-weight: 800;
`
class ExchangeStep extends Component {
  render() {
    const { handleSubmit, switchableProducts, desiredItem } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Grid container justify="center" alignItems="center" direction='column'>
           <Typography align="center" style={{fontSize:'1.4em', marginTop: 20}}>
             {translate("exchange_item")}
           </Typography>
           <Field name="desiredItem"
                  dataCy="desired-item"
                  style={{width: '80%', marginTop: 10}}
                  component={RenderSelectField}
                  items={switchableProducts}
                  type="text"/>
            {
              switchableProducts[switchableProducts.findIndex(x => x.id === desiredItem)] ?
              <div>
                {
                  switchableProducts[switchableProducts.findIndex(x => x.id === desiredItem)].quantity <= 0
                  ?
                  // <div style={{textAlign: 'center', marginTop: 10}}>
                  //   <HelperTextLowQuantity>
                  //   {translate("available_15days")}
                  //   </HelperTextLowQuantity>
                  // </div>
                  ''
                  :
                  ''
                }
              </div>
              :
              ''
            }
           <Button type="submit"
                   id="desired-item-button-validate"
                   variant="contained"
                   color="primary"
                   style={{marginTop: 40}}>
                   {translate("submit")}
           </Button>
        </Grid>
      </form>
    )
  }
}
const selector = formValueSelector('exchangeStep')

const mapStateToPros = (state) => {
  return {
    desiredItem: selector(state, 'desiredItem'),
    switchableProducts: state.returnItem.switchableProducts
  }
}

ExchangeStep = reduxForm({
  form: 'exchangeStep',
  onSubmit: (value, dispatch, props) => {
    const { switchableProducts } = props;
    let index = switchableProducts.findIndex(x => x.id === +value.desiredItem)
    let item = {
                description: switchableProducts[index].name,
                color: switchableProducts[index].color,
                size: switchableProducts[index].size,
                ean13: switchableProducts[index].barcodeValue
              }
    dispatch(setDesiredItem(item))
    dispatch(handleChangeSwipeableIndex())
  },
  validate
})(ExchangeStep)

export default connect(mapStateToPros)(ExchangeStep);
