import React, {Component}  from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import translate from '../../../i18n/translate';
import RenderTextField from '../../utilis/renderField/RenderTextField'
import RenderSelectField from '../../utilis/renderField/RenderSelectField'

import submitExchangeAdressForm from './submitExchangeAdressForm'

import countryList from '../../returnAdress/countryList.json';

class ExchangeAdress extends Component {
  componentDidMount() {
    const { prefilled, recipientAddress } = this.props
    prefilled('street', recipientAddress.street)
    prefilled('city', recipientAddress.city)
    prefilled('postalCode', recipientAddress.postalCode)
    prefilled('countryCode', recipientAddress.countryCode)
  }

  render() {
    const { handleSubmit } = this.props;
      return (
        <div>
          <DialogTitle id="form-dialog-title" style={{textAlign: 'center'}}>
          {translate("return_place")}
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={8} direction="row">
                <Grid container item spacing={0} justify="center" >
                  <Grid item xs={10} md={8}>
                    <Field id="street"
                           style={{width: '95%'}}
                           name="street"
                           label={translate("address")}
                           component={RenderTextField}
                           type="text"/>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={8} direction="row">
                <Grid container item spacing={0} justify="center" >
                  <Grid item xs={10} md={4}>
                    <Field id="city"
                           style={{width: '95%'}}
                           name="city"
                           label={translate("city")}
                           component={RenderTextField}
                           type="text"/>
                  </Grid>
                  <Grid item xs={10} md={4}>
                    <Field id="postal-code"
                           style={{width: '95%'}}
                           name="postalCode"
                           label={translate("postal_code")}
                           component={RenderTextField}
                           type="text"/>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={8} direction="row">
                <Grid container item spacing={0} justify="center" >
                  <Grid item xs={10} md={8}>
                    <Field  id="country-code"
                            style={{width: '95%'}}
                            name="countryCode"
                            placeHolder={translate("country")}
                            dataCy="adress"
                            component={RenderSelectField}
                            items={countryList}/>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </div>
      )
    }
  }

  const mapStateToProps = state => ({
    recipientCriteria : state.login.recipientCriteria,
    recipientAddress: state.returnItem.currentUserInformations.recipientAddress
  })

  const mapDispatchToProps = dispatch => ({
    prefilled: (field, value) =>
      dispatch(change('exchangeAdress', field, value))
  })

  ExchangeAdress = reduxForm({
    form: 'exchangeAdress',
    onSubmit: submitExchangeAdressForm
  })(ExchangeAdress)

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeAdress);
