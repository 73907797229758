import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import SwipeableViews from 'react-swipeable-views'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'
import { cancelReturnVoucher } from '../../api/api'
import { resetState } from '../returnItems/actions'

import translate from '../../i18n/translate';

const CircularProgressConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7%;
`

class ConfirmCancelDialog extends Component {
  state = {
    swipeableIndex: 0,
    loading: false,
    success: true,
    errorCode: ''
  }

  reset () {
    this.setState({swipeableIndex: 0})
    this.props.resetState()
  }
  render() {
    const { swipeableIndex, loading, success, errorCode } = this.state
    const { isOpen, handleClose, token, id, history } = this.props
    return (
      <div>
        <Dialog open={isOpen}>
          <SwipeableViews index={swipeableIndex}>
            <div>
              <DialogContent>
                <DialogContentText style={{textAlign: 'center'}} id="confirmation-message">
                  {translate('confirm_cancel_courier')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={handleClose} id='close-modal'>
                  {translate('return')}
                </Button>
                <Button color="primary"
                        autoFocus
                        id="validate-cancel-request-courier"
                        onClick={
                          async () => {
                            this.setState({loading: true, swipeableIndex: 1})
                            let response = await cancelReturnVoucher(token)
                            if (response.data.success === true) {
                              this.setState({loading: false, success: true})
                            } else {
                              this.setState({loading: false, success: false, errorCode: response.data.errorCode})
                            }
                          }
                        }>
                  {translate('submit')}
                </Button>
              </DialogActions>
            </div>
            <div>
              {
                loading ?
                <CircularProgressConatiner>
                  <CircularProgress id='circle-loader'/>
                </CircularProgressConatiner>
                :
                <div>
                  {
                    success === true ?
                    <DialogContent>
                      <DialogContentText style={{textAlign: 'center'}}>
                        {translate('courier_cancelled')}
                      </DialogContentText>
                    </DialogContent>
                    :
                    <DialogContent>
                      <DialogContentText style={{textAlign: 'center'}}>
                        {translate('courier_cancelled_error')}
                        <span role="img" aria-labelledby="confuseFace">&nbsp;😕</span>
                        <br/>
                        Envoyez-nous un email à&nbsp;
                        {translate('send_error_1')}
                        <a href={`mailto:sav@sympl.fr?subject=Erreur lors de l'annulation de mon bon de retour id: ${id} - code erreur : ${errorCode}`}>sav@sympl.fr</a>
                        {translate('send_error_2')}
                        <span style={{fontWeight: 800}}>&nbsp;{errorCode}&nbsp;</span>
                        {translate('send_error_3')}
                        <span style={{fontWeight: 800}}>&nbsp;{id}</span>
                      </DialogContentText>
                    </DialogContent>
                  }
                  <DialogActions style={{justifyContent: 'center'}}>
                    <Button id='return-to-connexion-page'
                            onClick={() => {
                              this.reset()
                              history.push('/')
                            }}
                            color="primary">
                            {translate('return_login')}
                    </Button>
                  </DialogActions>
                </div>
              }
            </div>
          </SwipeableViews>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.returnItem.currentUserInformations.token
  }
}

const mapDispatchToProps = dispatch => ({
  resetState: () =>
    dispatch(resetState())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmCancelDialog))
