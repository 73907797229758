import React, {Component}  from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RenderTextField from '../../utilis/renderField/RenderTextField'
import validateEmail from '../../utilis/validateEmail'
import submitEmailFormCarrier from './submitEmailFormCarrier'
import translate from '../../../i18n/translate';
class Email extends Component {
  componentDidMount () {
    const { recipientCriteria } = this.props
    if (validateEmail(recipientCriteria)) {
      this.props.changeField('contactEmail', recipientCriteria)
    }
  }
  render() {
    const { handleSubmit } = this.props;
      return (
        <div>
          <DialogTitle id="form-dialog-title" style={{textAlign: 'center'}}>
          {translate("email_input")}
          </DialogTitle>
          <DialogContent style={{textAlign: 'center'}}>
            <form onSubmit={handleSubmit}>
              <Field  id="email"
                      name="contactEmail"
                      label={translate("email")}
                      style={{width: '90%'}}
                      component={RenderTextField}/>
            </form>
          </DialogContent>
        </div>
      )
    }
  }

const mapStateToProps = state => ({
  recipientCriteria : state.login.recipientCriteria
})

const mapDispatchToProps = dispatch => ({
  changeField: (field, value) =>
    dispatch(change('emailFormCarrier', field, value))
})

Email = reduxForm({
  form: 'emailFormCarrier',
  onSubmit: submitEmailFormCarrier
})(Email)

export default connect(mapStateToProps, mapDispatchToProps)(Email);
