import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import translate from '../../i18n/translate';

class internalError extends Component {
  render() {
    return(
      <div style = {{backgroundColor: '#fff', paddingBottom: '40px'}}>
      <Grid container
            direction="column"
            justify="center"
            alignItems="center"
            style={{marginTop: '5%'}}>
              <div style={{fontSize: '1em'}}>
                <h1>{translate('error')} - 500</h1>
              </div>
              <p>{translate('error_page_1')}</p>
              <p>{translate('error_page_2')}</p>
        </Grid>
      </div>
    )
  }
}

export default withRouter(internalError)
